import React from "react";
import {
  getAllMetaData, deleteMeta,
  getAllB2BData
} from "../../Meta.Apis";
import { useState, useEffect } from "react";
import { storyTabPublish } from "../../Meta.Constants";
import SweetAlert from "react-bootstrap-sweetalert";
import AllB2bList from "../../../../../../Components/AllB2bList";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.css";
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { searchBusinessUsersApi, sendReport } from "../../../Business/Business.Api";
const select_user = {
  marginTop: "10px",
  position: "absolute",
  backgroundColor: "#fff",
  padding: "0px 20px 20px 20px",
  width: "100%",
  height: "240px",
  overflowY: "scroll",
  scrollbarWidth: "none",
  zIndex: "9",
};
const select_search_item = {
  fontSize: "0.9rem",
  fontWeight: "500",
  lineHeight: "1.23",
  textAlign: "left",
  color: "#505050",
  padding: "1rem 0",
  borderBottom: "1px solid #d3d3d3",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
};
const AllB2bUsers = () => {
  const [errorRes, setErrorRes] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [storyData, setStoryData] = useState([]);
  const [publishID, setPublishID] = useState("");
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  });
  const { currentPage, totalSize } = paginationValue;
  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 1,
  });

  const [isLoading, setIsLoading] = useState(false);
  // const getBusinessList = async () => {
  //   const { data } = await businessUsers();
  // }
  const searchUserData = async (text) => {
    try {
      const { data } = await searchBusinessUsersApi(text);
      setBusinessList(data.data);
      if (data.data?.length) {
        setShow(true)
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [search, setSearch] = useState("");

  const handleDdChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    searchUserData(searchBar);
  };

  const getAllMeta = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getAllB2BData(queryParams);
      // alert(data)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data?.count,
      });
      var result =
        data.data &&
        data.data.length > 0 &&
        data.data?.map(function (el, i) {
          var o = {
            ...el,
            'createdAt': el?.createdAt?.slice(0, 10),
            'shelfDate': el?.shelfDate?.slice(0, 10),
            'mobileNo': el?.contactNo ? `+${el?.dialCode} ${el?.contactNo}` : '',
            'businessName': `${el?.businessUserId?.firstName} ${el?.businessUserId?.lastName}`
          };
          return o;
        });
      setStoryData(result || []);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllUnPublishedStories = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getUnPublishedStoryApi(queryParams);
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      });
      setStoryDataUnpublish(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [businessList, setBusinessList] = useState([])
  useEffect(() => {
    getAllMeta({ page: 1, limit: 10 });
    searchUserData('')
    // getAllUnPublishedStories({ page: 1, limit: 10 });
  }, []);

  const handleUnpublishStoryClick = async (storyId) => {
    setUnpublishId(storyId);
    setShowUnPublishAlert(true);
  };
  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    getAllMeta({ page, limit: sizePerPage, startDate: startRangeDate || null, endDate: endRangeDate ? getUTCEndDate(endRangeDate) : null, startDateShelfDate: startShelfRangeDate, endDateShelfDate: endShelfRangeDate ? getUTCEndDate(endShelfRangeDate) : null, businessUserId: selectedBusiness?._id });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };
  const handleUnpublishedTablePageChange = (type, { page, sizePerPage }) => {
    getAllUnPublishedStories({ page, limit: sizePerPage });
    setUnpublishedPaginationValue({
      ...unpublishedPaginationValue,
      unpublishedCurrentPage: page,
    });
  };

  const unpublishStoryAction = async () => {
    try {
      await deleteMeta(unpublishId);
      getAllMeta({ page: 1, limit: 100 });
      setUnpublishId("");
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      console.log(error, "error.response.data.error");
      setErrorRes(error.response.data.error);
      setShowErr(true);
    }
  };

  const handlePublishStoryClick = async (storyId) => {
    setPublishID(storyId);
    setShowPublishAlert(true);
  };

  const publishStoryAction = async () => {
    try {
      await publishStory(publishID);
      setPublishID("");
      setShowPublishAlert(false);
    } catch (error) { }
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      width: '200px',
      // marginTop: '-14px', // Set the margin-top here
    }),
  };
  const [startRangeDate, setStartRangeDate] = useState(0);
  const [endRangeDate, setEndRangeDate] = useState(0);
  const [startShelfRangeDate, setStartShelfRangeDate] = useState(0);
  const [endShelfRangeDate, setEndShelfRangeDate] = useState(0);
  const getUTCEndDate = (date) => {
    const istDate = new Date(date);
    const utcDate = new Date(istDate.getTime() - (istDate.getTimezoneOffset() * 60000));
    utcDate.setHours(23, 59, 59, 999);
    return utcDate;

  }

  const getDataByDate = async (data, startDate, endDate) => {
    if (data) {
      try {
        getAllMeta({ page: 1, limit: 10, startDate: startDate, endDate: endDate ? getUTCEndDate(endDate) : null, startDateShelfDate: startShelfRangeDate || null, endDateShelfDate: endShelfRangeDate ? getUTCEndDate(endShelfRangeDate) : null, businessUserId: selectedBusiness?._id });
      } catch (error) { }
    }
  };

  const handleChange = (data) => {
    if (data && !data[0]) {
      if (startRangeDate != 0 && endRangeDate == 0) {
        endRangeDate == 0 && data && setEndRangeDate(data)
      } else if (startRangeDate == 0 && endRangeDate == 0) {
        startRangeDate == 0 && data && setStartRangeDate(data)
      }
    } else if (data && data[0] && data[1]) {
      data && data[0] && setStartRangeDate(data[0])
      data && data[1] && setEndRangeDate(data[1])
      getDataByDate(data, data[0], data[1]);
    }
    else {//no dates
      getShelfDataByDate(true, null, null);
    }
  };

  const getShelfDataByDate = async (data, startDate, endDate) => {
    if (data) {
      try {
        getAllMeta({ page: 1, limit: 10, startDate: startRangeDate || null, endDate: endRangeDate ? getUTCEndDate(endRangeDate) : null, startDateShelfDate: startDate, endDateShelfDate: endDate ? getUTCEndDate(endDate) : null, businessUserId: selectedBusiness?._id });
      } catch (error) { }
    }
  };
  const handleShelfChange = (data) => {
    if (data && !data[0]) {
      if (startShelfRangeDate != 0 && endShelfRangeDate == 0) {
        endShelfRangeDate == 0 && data && setEndShelfRangeDate(data)
      } else if (startShelfRangeDate == 0 && endShelfRangeDate == 0) {
        startShelfRangeDate == 0 && data && setStartShelfRangeDate(data)
      }
    } else if (data && data[0] && data[1]) {
      data && data[0] && setStartShelfRangeDate(data[0])
      data && data[1] && setEndShelfRangeDate(data[1])
      getShelfDataByDate(data, data[0], data[1]);
    }
    else {//no dates
      getShelfDataByDate(true, null, null);
    }
  };
  const [show, setShow] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState();
  const filterByBusiness = (business) => {
    setSelectedBusiness(business)
    setSearch(business.firstName)
    getAllMeta({ page: 1, limit: 10, startDate: startRangeDate || null, endDate: endRangeDate ? getUTCEndDate(endRangeDate) : null, startDateShelfDate: startShelfRangeDate, endDateShelfDate: endShelfRangeDate ? getUTCEndDate(endShelfRangeDate) : null, businessUserId: business?._id });
  }
  const [csvLoad, setCsvLoad] = useState(false);
  const sendCsv = async () => {
    setCsvLoad(true)
    try {
      await sendReport({ startDate: startRangeDate || null, endDate: endRangeDate ? getUTCEndDate(endRangeDate) : null, startDateShelfDate: startShelfRangeDate, endDateShelfDate: endShelfRangeDate ? getUTCEndDate(endShelfRangeDate) : null, businessUserId: selectedBusiness?._id });
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setCsvLoad(false)
    }
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div> <FormGroup>
          <Label>Created Date</Label> &nbsp;
          <DateRangePicker
            onOk={() => getDataByDate(false)}
            onSelect={(data) => handleChange(data)}
            onChange={(data) => handleChange(data)}
          />
        </FormGroup></div>
        <div> <FormGroup>
          <Label>Shelf Date</Label> &nbsp;
          <DateRangePicker
            onOk={() => getShelfDataByDate(false)}
            onSelect={(data) => handleShelfChange(data)}
            onChange={(data) => handleShelfChange(data)}
          />
        </FormGroup></div>
        <div style={{ position: "relative" }}>
          <input
            name="search"
            value={search}
            placeholder="Search Business"
            style={{
              width: "200px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "1px solid black",
              padding: "5px",
            }}
            onChange={handleDdChange}
            // onBlur={hideComponent}
            autoComplete="off"
          />
          {show === true && (
            <>
              <div
                style={select_user}
              >
                {businessList && businessList.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  businessList &&
                  businessList.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => { filterByBusiness(item); setShow(false) }}
                      // ; setRowId(item.isDisabled) }
                      >
                        {/* {item && item.isDisabled === false && (
                          <div style={selector1}></div>
                        )}
                        {item && item.isDisabled === true && (
                          <div style={selector3}></div>
                        )} */}
                        <div>{item ? item.firstName : "-"} {item ? item.lastName : ""}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
        <Button
          color="success"
          className="waves-effect waves-light"
          disabled={csvLoad}
          style={{ cursor: 'pointer', marginBottom: '16px' }} onClick={() => { sendCsv() }}
        >
          {csvLoad ? <>Sending report...</> : <>Export to Csv</>}

        </Button>
      </div>
      <AllB2bList
        tableData={storyData}
        tableHeading={storyTabPublish}
        deleteMeta={handleUnpublishStoryClick}
        handleTablePageChange={handlePublishedTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      {showErr ? (
        <SweetAlert
          warning
          title={errorRes}
        // onConfirm={() => window.location.reload()}
        ></SweetAlert>
      ) : null}

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={() => {
            unpublishStoryAction()
            setShowUnPublishAlert(false)
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Deleted"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            // window.location.reload();
          }}
        ></SweetAlert>
      ) : null}

    </>
  );
};

export default AllB2bUsers;
