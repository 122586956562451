export const storyTabPublish = [
  {
    text: "S.No",
    dataField: "sNo"
  },
  {
    text: "Business",
    dataField: "businessName"
  },
  {
    text: "Name",
    dataField: "name"
  },
  {
    text: "Email",
    dataField: "email"
  },
  // {
  //   text: "Dial Code",
  //   dataField: "dialCode"
  // },
  {
    text: "Contact No",
    dataField: "mobileNo"
  }
  ,
  {
    text: "Status",
    dataField: "status"
  }
  ,
  {
    text: "Shlef Date",
    dataField: "shelfDate"
  }
  ,
  {
    text: "Created On",
    dataField: "createdAt"
  }
]

